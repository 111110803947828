.footer {
  background-color: #373737;
  color: white;
  flex-shrink: 0;
  max-width: 100%;
  padding: 0%;
  margin: 0%;
}

.noMarging {
  margin: 0;
}

.rowFooter {
  margin-left: 5%;
  margin-right: 5%;
  height: 150px;
}

.colFooter {
  text-align: center;
  margin: auto;
}

.footerEmailLink, .footerEmailLink:hover{
    color: var(--trafoo-primary-color);
}

.smallSizeFooter{
  font-size: 12px;
}
.mail_icon{
  width: 20px;
  margin-right: 8px;
  margin-bottom: 5px;
}
