.RequestInfo-table {
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid var(--trafoo-secondary-color);
  margin-top: 15px;
  margin-bottom: 5px;
}


.RequestInfo-smallIcon{
  width: 20px;
}