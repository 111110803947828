.LoginPage {
  margin-top: 20px;
}


.LoginButton{
  margin: 0 auto;
}

.LoginContainer{
  margin-top: 50px;
  width: 300px;
}

.LoginTitle{
  text-align: center;
}

.LoginSignUpText{
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}