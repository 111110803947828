.HomePage {
  margin-top: 300px;
  text-align: center;
  font-size: large;
}

.imageLargeLogo {
  display: block;
  text-align: center;
  width: 100%;
  height: auto;
  opacity: 0.4;
  filter: alpha(opacity=40);
}

.imageIcons {
  display: block;
  max-width: 60px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 20px;
}

.rowHomepage100 {
  min-height: 100vh;
}

.rowHomepage40 {
  min-height: 40vh;
  padding-left: 20px;
  padding-right: 20px;
}

.HomePage-CenteredText {
  text-align: center;
  max-width: 900px;
  margin: 0 auto;
}

.graybackground {
  background-color: rgb(250, 250, 250);
}

.HomePage-CenteredTextImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 0;
  font-size: 30px;
  font-weight: bold;
  color: var(--trafoo-primary-color);
  float: none;
  width: 100%;
}

.HomePage-CenteredTextImage-Button {
  font-size: 22px;
  margin: 12px;
  color: white;
  border-color: var(--trafoo-secondary-color);
  background: var(--trafoo-secondary-color);
}

.HomePage-SmallTitle {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
.HomePage-Heading {
  font-weight: bold;
  text-align: center;
  max-width: 900px;
  padding-bottom: 40px;
  margin: 0 auto;
}

.HomePage-RedTextColor {
  color: var(--trafoo-primary-color);
  font-weight: bold;
  margin-top: 40px;
}

.whiteBackground {
  background-color: white;
}

#about_us{
  margin-top: 0;
  margin-bottom: 0;
}

#services{
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.HomePage-SmallIconsContainer{
  padding-bottom: 40px;
}

.HomePage-Screenshot{
  max-width: 100%;
  border-radius: 5px;
}

.HomePage-ScreenshotTextContainer{
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: auto;
  margin-bottom: auto;
  width: 300px;
  float: center;
  max-height: 100%;
}

.HomePage-ScreenshotHeading{
  font-weight: bold;
}

