.SearchPageContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
}

.SearchPageImage {
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 150px;
  height: 150px;
  margin: auto;
}

.searchPageHeader {
  width: 250px;
  max-width: 250px;
  min-height: 50px;
}

.SearchPageButtonImage {
  border-color: var(--trafoo-background-color);
  background: var(--trafoo-background-color);
  margin-left: 20px;
  margin-right: 20px;
}

.SearchPageButtonImage.disabled,
.SearchPageButtonImage:disabled,
.SearchPageButtonImage:hover,
.SearchPageButtonImage:not(:disabled):not(.disabled):active,
.SearchPageButtonImage:not(:disabled):not(.disabled).active,
.show > .SearchPageButtonImage.dropdown-toggle .SearchPageButtonImage:not(:disabled):not(.disabled).active:focus,
.SearchPageButtonImage:not(:disabled):not(.disabled):active:focus,
.show > .SearchPageButtonImage.dropdown-toggle:focus {
  color: transparent !important;
  border-color: transparent !important;
  background-color: transparent !important;
  box-shadow: 0 0 0 !important;
}

.SearchPageHeaderMain {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

.SearchPageInputGroup {
  max-width: 400px;
}

.searchPageFormGroup {
  max-width: 400px;
  margin: auto;
  height: 100px;
}

.SearchPageInputGroupAddon {
  margin: auto;
}

// TODO allgin text to center
.input-group-text {
  min-width: 55px;
  text-align: center;
  margin: auto;
}

.SearchPageMarginLeftAndRight {
  margin-left: 50px;
  margin-right: 50px;
}

.searchSmallIcon {
  width: 50px;
  float: right;
}

.searchClickableView {
  cursor: pointer;
}

.SearchPageSubmitButton {
  margin-top: 30px;
  min-width: 180px;
  font-size: 20px;
}

.searchPageSpecification {
  margin-top: 30px;
}

.searchPageCustomBackground {
  background-color: var(--trafoo-primary-color);
  min-height: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.whiteDivider {
  height: 2px;
  background-color: white;
}

.dividerEmpty {
  height: 25px;
}
