@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

:root {
  --trafoo-primary-color: #d60000;
  --trafoo-secondary-color: #484339;
  --footer-height: 50px;
  --trafoo-background-color: #fafafa;
}

body {
  background-color: var(--trafoo-background-color);
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Lato", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Lato", sans-serif;
}

html {
  height: 100%;
}

.content {
  flex: 1 0 auto;
  min-height: 100vh;
}
