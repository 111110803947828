.WelcomePage {
  margin-top: 10px;
  margin-right: 20%;
  margin-left: 20%;
}

.Welcome{
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}