.DesignsTable-modal-button {
    background: var(--trafoo-primary-color) !important;
    border-color: var(--trafoo-primary-color) !important;
  }
  
  .DesignsTable-modal-button:hover,
  .DesignsTable-modal-button:focus,
  .DesignsTable-modal-button:active {
    background: lightcoral !important;
    border-color: lightcoral !important;
  }