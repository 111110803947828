.SignUpPage {
  margin-top: 10px;
  margin-right: 20%;
  margin-left: 20%;
}

.SingUpTitle{
  margin-top: 10px;
  text-align: center;
}

.SignUpButton{
  margin: 0 auto;
}

.SignUpWelcome{
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}