.ResultPage-table {
  margin-top: 40px;
  width: 100%;
}

.ResultPage-buttons {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.ResultPage-count-button {
  color: var(--trafoo-secondary-color);
  border-color: var(--trafoo-secondary-color);
}

.ResultPage-count-button:hover {
  background: var(--trafoo-secondary-color);
  border-color: var(--trafoo-secondary-color);
}

.ResultPage-count-badge {
  background-color: var(--trafoo-primary-color);
}

.ResultPage-modal-close {
  background: var(--trafoo-primary-color) !important;
  border-color: var(--trafoo-primary-color) !important;
}

.ResultPage-modal-close:hover,
.ResultPage-modal-close:focus,
.ResultPage-modal-close:active {
  background: lightcoral !important;
  border-color: lightcoral !important;
}

.ResultPage-modal-dialog {
  width: auto;
  max-width: 1280px;
  overflow: auto;
}

.ResultPage-modal-body {
  width: auto;
  max-height: 720px;
  overflow: auto;
}
