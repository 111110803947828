.NavigationBar-logo {
  height: 30px;
  max-width: 100%;
  width: auto;
}

.NavigationBar-NavLink{
  color: var(--trafoo-secondary-color);
}
.NavigationBar-NavLink:link {
  color: var(--trafoo-secondary-color);
  text-decoration: none;
}
.NavigationBar-NavLink:hover {
  color: var(--trafoo-primary-color);
}
.NavigationBar-Navbar:active {
  color: var(--trafoo-secondary-color);
}

.NavigationBar-Navbar{
  background-color: white
}